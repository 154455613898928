// English
exports['en'] = {
  edit: 'Edit',
  del: 'Delete selected',
  back: 'Back',
  addNode: 'Add Node',
  addEdge: 'Add Edge',
  editNode: 'Edit Node',
  editEdge: 'Edit Edge',
  addDescription: 'Click in an empty space to place a new node.',
  edgeDescription: 'Click on a node and drag the edge to another node to connect them.',
  editEdgeDescription: 'Click on the control points and drag them to a node to connect to it.',
  createEdgeError: 'Cannot link edges to a cluster.',
  deleteClusterError: 'Clusters cannot be deleted.',
  editClusterError: 'Clusters cannot be edited.'
};
exports['en_EN'] = exports['en'];
exports['en_US'] = exports['en'];

// German
exports['de'] = {
  edit: 'Editieren',
  del: 'L\u00f6sche Auswahl',
  back: 'Zur\u00fcck',
  addNode: 'Knoten hinzuf\u00fcgen',
  addEdge: 'Kante hinzuf\u00fcgen',
  editNode: 'Knoten editieren',
  editEdge: 'Kante editieren',
  addDescription: 'Klicke auf eine freie Stelle, um einen neuen Knoten zu plazieren.',
  edgeDescription: 'Klicke auf einen Knoten und ziehe die Kante zu einem anderen Knoten, um diese zu verbinden.',
  editEdgeDescription: 'Klicke auf die Verbindungspunkte und ziehe diese auf einen Knoten, um sie zu verbinden.',
  createEdgeError: 'Es ist nicht m\u00f6glich, Kanten mit Clustern zu verbinden.',
  deleteClusterError: 'Cluster k\u00f6nnen nicht gel\u00f6scht werden.',
  editClusterError: 'Cluster k\u00f6nnen nicht editiert werden.'
};
exports['de_DE'] = exports['de'];

// Spanish
exports['es'] = {
  edit: 'Editar',
  del: 'Eliminar selecci\u00f3n',
  back: 'Atr\u00e1s',
  addNode: 'A\u00f1adir nodo',
  addEdge: 'A\u00f1adir arista',
  editNode: 'Editar nodo',
  editEdge: 'Editar arista',
  addDescription: 'Haga clic en un lugar vac\u00edo para colocar un nuevo nodo.',
  edgeDescription: 'Haga clic en un nodo y arrastre la arista hacia otro nodo para conectarlos.',
  editEdgeDescription: 'Haga clic en un punto de control y arrastrelo a un nodo para conectarlo.',
  createEdgeError: 'No se puede conectar una arista a un grupo.',
  deleteClusterError: 'No es posible eliminar grupos.',
  editClusterError: 'No es posible editar grupos.'
};
exports['es_ES'] = exports['es'];

//Italiano
exports['it'] = {
  edit: 'Modifica',
  del: 'Cancella la selezione',
  back: 'Indietro',
  addNode: 'Aggiungi un nodo',
  addEdge: 'Aggiungi un vertice',
  editNode: 'Modifica il nodo',
  editEdge: 'Modifica il vertice',
  addDescription: 'Clicca per aggiungere un nuovo nodo',
  edgeDescription: 'Clicca su un nodo e trascinalo ad un altro nodo per connetterli.',
  editEdgeDescription: 'Clicca sui Punti di controllo e trascinali ad un nodo per connetterli.',
  createEdgeError: 'Non si possono collegare vertici ad un cluster',
  deleteClusterError: 'I cluster non possono essere cancellati',
  editClusterError: 'I clusters non possono essere modificati.'
};
exports['it_IT'] = exports['it'];

// Dutch
exports['nl'] = {
  edit: 'Wijzigen',
  del: 'Selectie verwijderen',
  back: 'Terug',
  addNode: 'Node toevoegen',
  addEdge: 'Link toevoegen',
  editNode: 'Node wijzigen',
  editEdge: 'Link wijzigen',
  addDescription: 'Klik op een leeg gebied om een nieuwe node te maken.',
  edgeDescription: 'Klik op een node en sleep de link naar een andere node om ze te verbinden.',
  editEdgeDescription: 'Klik op de verbindingspunten en sleep ze naar een node om daarmee te verbinden.',
  createEdgeError: 'Kan geen link maken naar een cluster.',
  deleteClusterError: 'Clusters kunnen niet worden verwijderd.',
  editClusterError: 'Clusters kunnen niet worden aangepast.'
};
exports['nl_NL'] = exports['nl'];
exports['nl_BE'] = exports['nl'];

// Portuguese Brazil
exports['pt-br'] = {
  edit: 'Editar',
  del: 'Remover selecionado',
  back: 'Voltar',
  addNode: 'Adicionar nó',
  addEdge: 'Adicionar aresta',
  editNode: 'Editar nó',
  editEdge: 'Editar aresta',
  addDescription: 'Clique em um espaço em branco para adicionar um novo nó',
  edgeDescription: 'Clique em um nó e arraste a aresta até outro nó para conectá-los',
  editEdgeDescription: 'Clique nos pontos de controle e os arraste para um nó para conectá-los',
  createEdgeError: 'Não foi possível linkar arestas a um cluster.',
  deleteClusterError: 'Clusters não puderam ser removidos.',
  editClusterError: 'Clusters não puderam ser editados.'
};
exports['pt-BR'] = exports['pt-br'];
exports['pt_BR'] = exports['pt-br'];
exports['pt_br'] = exports['pt-br'];

// Russian
exports['ru'] = {
  edit: 'Редактировать',
  del: 'Удалить выбранное',
  back: 'Назад',
  addNode: 'Добавить узел',
  addEdge: 'Добавить ребро',
  editNode: 'Редактировать узел',
  editEdge: 'Редактировать ребро',
  addDescription: 'Кликните в свободное место, чтобы добавить новый узел.',
  edgeDescription: 'Кликните на узел и протяните ребро к другому узлу, чтобы соединить их.',
  editEdgeDescription: 'Кликните на контрольные точки и перетащите их в узел, чтобы подключиться к нему.',
  createEdgeError: 'Невозможно соединить ребра в кластер.',
  deleteClusterError: 'Кластеры не могут быть удалены',
  editClusterError: 'Кластеры недоступны для редактирования.'
};
exports['ru_RU'] = exports['ru'];

// Chinese
exports['cn'] = {
  edit: '编辑',
  del: '删除选定',
  back: '返回',
  addNode: '添加节点',
  addEdge: '添加连接线',
  editNode: '编辑节点',
  editEdge: '编辑连接线',
  addDescription: '单击空白处放置新节点。',
  edgeDescription: '单击某个节点并将该连接线拖动到另一个节点以连接它们。',
  editEdgeDescription: '单击控制节点并将它们拖到节点上连接。',
  createEdgeError: '无法将连接线连接到群集。',
  deleteClusterError: '无法删除群集。',
  editClusterError: '无法编辑群集。'
};
exports['zh_CN'] = exports['cn'];

// Ukrainian
exports['uk'] = {
  edit: 'Редагувати',
  del: 'Видалити обране',
  back: 'Назад',
  addNode: 'Додати вузол',
  addEdge: 'Додати край',
  editNode: 'Редагувати вузол',
  editEdge: 'Редагувати край',
  addDescription: 'Kлікніть на вільне місце, щоб додати новий вузол.',
  edgeDescription: 'Клікніть на вузол і перетягніть край до іншого вузла, щоб їх з\'єднати.',
  editEdgeDescription: 'Клікніть на контрольні точки і перетягніть їх у вузол, щоб підключитися до нього.',
  createEdgeError: 'Не можливо об\'єднати краї в групу.',
  deleteClusterError: 'Групи не можуть бути видалені.',
  editClusterError: 'Групи недоступні для редагування.'
};
exports['uk_UA'] = exports['uk'];

// French
exports['fr'] = {
  edit: 'Éditer',
  del: 'Effacer la sélection',
  back: 'Retour',
  addNode: 'Ajouter un nœud',
  addEdge: 'Ajouter un lien',
  editNode: 'Éditer le nœud',
  editEdge: 'Éditer le lien',
  addDescription: 'Cliquez dans un endroit vide pour placer un nœud.',
  edgeDescription: 'Cliquez sur un nœud et glissez le lien vers un autre nœud pour les connecter.',
  editEdgeDescription: 'Cliquez sur les points de contrôle et glissez-les pour connecter un nœud.',
  createEdgeError: 'Impossible de créer un lien vers un cluster.',
  deleteClusterError: 'Les clusters ne peuvent pas être effacés.',
  editClusterError: 'Les clusters ne peuvent pas être édités.'
};
exports['fr_FR'] = exports['fr'];

// Czech
exports['cs'] = {
  edit: 'Upravit',
  del: 'Smazat výběr',
  back: 'Zpět',
  addNode: 'Přidat vrchol',
  addEdge: 'Přidat hranu',
  editNode: 'Upravit vrchol',
  editEdge: 'Upravit hranu',
  addDescription: 'Kluknutím do prázdného prostoru můžete přidat nový vrchol.',
  edgeDescription: 'Přetažením z jednoho vrcholu do druhého můžete spojit tyto vrcholy novou hranou.',
  editEdgeDescription: 'Přetažením kontrolního vrcholu hrany ji můžete připojit k jinému vrcholu.',
  createEdgeError: 'Nelze připojit hranu ke shluku.',
  deleteClusterError: 'Nelze mazat shluky.',
  editClusterError: 'Nelze upravovat shluky.'
};
exports['cs_CZ'] = exports['cs'];
